<template>
    <div id="Donate">
        <section id="donate-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">おつなぎ徳積みの理</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-5.jpg'),
                        require('@/assets/background/background-5-mb.jpg')
                    )
                "
            ></div>
        </section>
        <div id="donate-main" class="b25">
            <header class="w90 w80-mb">
                <div class="header-wrap">
                    <h2 class="f29 hide-mb">おつなぎ徳積みの理</h2>
                    <p v-html="wrap(donateIntro)" class="f14 f12-mb w80-mb"></p>
                </div>
                <div class="button-wrap">
                    <router-link
                        class="donate-button f18 f16-mb serif"
                        :to="{ name: 'DonateProcessHome' }"
                        >おつなぎ徳積み</router-link
                    >
                </div>
            </header>
            <main class="article-group w90 w80-mb">
                <article class="article-style purple" id="donate-intro">
                    <ul class="jump-list-style">
                        <li
                            v-for="(item, index) of donateArticles"
                            :key="'donate-menu-' + index"
                            class="f14 f12-mb"
                            @click="scrollToElement('#donate-article-' + index)"
                        >
                            <i class="icon-page-link f12 f12-mb"></i
                            >{{ ' ' + item.title }}
                        </li>
                    </ul>
                </article>
                <article
                    v-for="(item, index) in donateArticles"
                    :key="'donate-article-' + index"
                    :id="'donate-article-' + index"
                    class="article-style purple"
                >
                    <h3
                        v-if="item.title"
                        v-html="wrap(item.title)"
                        class="title w60 f29 f16-mb"
                    ></h3>
                    <p
                        v-if="item.article"
                        v-html="wrap(item.article)"
                        class="article w80 f14 f12-mb"
                    ></p>
                </article>
            </main>
        </div>
    </div>
</template>
<script>
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
import getDonateData from '@/data/getDonateData.js'
export default {
    name: 'Donate',
    setup() {
        const { donateIntro, donateArticles } = getDonateData()
        return {
            addBackgroundImage,
            wrap,
            scrollToElement,
            donateIntro,
            donateArticles,
        }
    },
}
</script>
<style lang="scss" scoped>
#donate-main {
    header {
        border-bottom: 1px solid $grayE;
        box-sizing: border-box;
    }
    .button-wrap {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        .donate-button {
            @include solidButtonStyle();
            width: 140px;
        }
    }
    .header-wrap {
        display: flex;
        align-items: center;
        min-height: 260px;
        h2 {
            @include h1Style();
            flex: 0 0 300px;
            margin-right: 50px;
            height: max-content;

            &:before {
                content: '';
                position: absolute;
                display: block;
                left: calc(50% - 130px);
                top: -90px;
                width: 260px;
                height: 260px;
                border-radius: 130px;
                background-color: rgba($color: $majorGold, $alpha: 0.2);
            }
        }
        p {
            line-height: 2em;
        }
    }
    @media screen and (min-width: 768px) {
        padding-bottom: 80px;
        header {
            padding: 80px;
        }
    }
    @media screen and(max-width:767px) {
        padding-bottom: 50px;
        header {
            padding: 50px 0;
        }
    }
}
</style>
