export default function () {
    const donateIntro =
        '「おつなぎ徳積み」とは、『大親神大生命』に対し奉り、生存の喜びと感謝の念をもって、世のため人のために、自らの能動的意志で行うプラスの行いです。\n \
        \n \
        世のため人のために行ったプラスの種が、「循環・還元の理法」によって結局、自分の運命や環境に「徳」となって大きく巡ってくるのです。「おつなぎ徳積みの理」は、他人にも自分にもプラスに循還してゆく大自然の真理法則の一つです。'
    const donateArticles = [
        {
            title: '徳積み＝生命財産の蓄積',
            article:
                '徳とは、生命に蓄積する財産です。より良く生きてゆくために必要な、自分自身の生命の力働です。人間社会では、お金が全く無ければ、人間社会で生きて行く事は出来ません。同じように、徳という生命財産が無くなると、私たち生命の御子は、大自然界で生きる事が出来なくなります。\n \
                \n \
                徳とは、この世＝親物質現象界で、他の生命のために良い事をした分だけ、『大親神大生命』が生命の御子らの生命につけて下さる財産です。\n \
                \n \
                植物や動物は、自分の体を他の生命に食べてもらい、他の生命の役に立つ事によって、少しずつ生命財産を貯めていきます。\n \
                人間の場合は、人のため・世のため、全生物のため、地球全体のために良い行いをする事によって、『大親神大生命』が生命財産＝徳をつけて下さいます。',
        },
        {
            title: '「徳」と「お金」の違い',
            article:
                '「徳」とは『大親神大生命』が認めて下さった生命の財産です。\n \
                生命そのものの財産ですから、永遠普遍の価値であり、いつでも・どこでも・何にでも通用します。今世だけではなく、来世にも、再来世にも通用します。親物質現象界（この世、物質・肉体の世界）だけではなく、霊界（心・魂の世界）にも、生命界（生命の世界）にも通用します。\n \
                \n \
                一方、人間社会の「お金」は、人間が認めた財産です。\n \
                人間同士が認め合って成り立っているお金は、人間が認めている時にだけ通用する刹那的な価値です。この世の人間社会の中でしか通用しませんし、時代や政策などによって価値が変化したり、通用しなくなったりします。\n \
                \n \
                また、徳とは、より良く生きるための“全て”に通用する価値です。\n \
                徳があれば、運命・環境を良くする事も、あらゆる人間関係を良くする事も、人や物との縁を良くする事も、才能や能力を開発発展する事も、よりプラスの観念や感情を保って生きる事も出来るのです。\n \
                \n \
                しかし、人間のお金がいくらあっても、運命が必ず良くなる、という事はありません。運命・環境は人間のお金で買うことは出来ません。人間のお金は、『大親神大生命』が認めて下さった生命の財産ではないからです。運命・環境は『大親神大生命』がそれぞれの徳の分だけ、生命の御子にお与え下さるものなのです。',
        },
        {
            title: '徳のある生き方と、徳のない生き方',
            article:
                '徳があれば、持っている徳の分だけ、美しい心や美しい考えをもって、より良く生きる事が出来ます。毎日、より大きな感謝と喜びをもって、幸せでプラスの人生を生きる事が出来ます。他に幸せや喜びを与えるプラスの生き方を行って、ますます徳を高めていきます。\n \
                \n \
                徳が無いと、人を羨んだり、憎んだり、嫌悪感をもったり、運命や環境に不足不満を持ったりして、悩み苦しむ不幸な人生を送る事になります。お金があっても徳が無ければ、かえってお金に執着し、失う事の不安に苛まれ、マイナスの人生や生き方になってしまうのです。\n \
                \n \
                \n \
                現代の地球人は、科学文明の発達によって物質的には豊かになりましたが、徳が無いために、より良く生きる事が出来ていません。\n \
                <ul class="disc-list-ul">\
                    <li class="disc-list-li">クレジットカードなどの技術を発達させればさせるほど、詐欺や犯罪が増えて、かえって不便で不安な生活を送っています。<li> \
                    <li class="disc-list-li">争いや戦争などに無駄なお金やエネルギーをかけてお互いに殺し合っています。<li> \
                    <li class="disc-list-li">国家予算を赤字にしてまでも軍隊を持ち、軍事兵器の開発戦争をしています。<li> \
                    <li class="disc-list-li">金を奪い合う経済競争を世界中で行い、金の変動相場制や株式の売買によってマネーゲームを行い、貧富の差を広げています。<li> \
                    <li class="disc-list-li">無駄なエネルギーと資源を消費して、地球を破壊し他の生物をどんどん絶滅させています。<li> \
                </ul>\
                徳が無いと、他に苦しみを与えるマイナスの生命活動を行って、ますます徳を失ってしまうのです。\n \
                \n \
                この世＝親物質現象界を生きる目的は、「徳」を高めるためです。\n \
                私たち人間は、銀行にお金を貯める事は一生懸命にやってきました。しかし、生命の貯金＝徳を高める事は、生命をかけて一生懸命にやった事はありません。「徳」という事が分からなかったからです。\n \
                \n \
                もし「徳」という生命財産を人類が持つ事が出来れば、お互いに助け合い、より良く生きていく事が出来るのです。「おつなぎ徳積みの理」は、自分自身にとっても、地球全体にとっても、絶対に必要不可欠な事なのです。',
        },
        {
            title: 'おつなぎ＝「繋ぎの理」',
            article:
                'おつなぎとは「繋ぎの理」です。「おつなぎ徳積み」を行わせて頂くと、『大親神大生命』の御親縁 \
                <sup class="smaller">※</sup> \
                を頂く事が出来ます。（※御親縁とは、「生命の大親」との御縁です。）\n \
                \n \
                徳とは、より良く生きて行くために必要な「生命の力働」です。\n \
                『大親神大生命』の大いなる生命のお力・お働きによって、運命・環境など、あらゆる現象・事柄が現わされています。『大親神大生命』のお力・お働きの一部を生命力働＝徳として頂くからこそ、私たちは、より良く生きて行く事が出来るのです。\n \
                『大親神大生命』のお力・お働きを頂くためには、御親縁を頂く事が絶対に必要不可欠なのです。',
        },
        {
            title: '「陰徳の理」',
            article:
                '『大親神大生命』は御自親の宣伝をなされずに、私たち全ての生命の御子らを生かして下さっておられます。これを「陰徳の理」と言います。\n \
                \n \
                種を土に蒔くと、土に隠れて分かりませんが、種はやがて芽を出して成長し、花を咲かせて実を稔らせます。見えるように種を蒔いたのでは、鳥に食べられてしまうか、風で吹き飛んでしまいます。\n \
                せっかく良い事をさせて頂いても、「自分はこれだけ良い事をしたんだ」と自分の行いの宣伝をしたら、その分だけ、誠は減ってしまうのです。\n \
                \n \
                「おつなぎ徳積みの理」を行う時に自分の名前を書くのは、理を悟っていない証拠となります。人間は、人に判ってもらおうとして、のし袋に名前を書きますが、これでは人間事になって、『大親神大生命』に対し奉り行う事にはなりませんので、注意してください。',
        },
    ]

    return { donateIntro, donateArticles }
}
